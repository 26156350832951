import { Fragment, useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { useHistory, Link } from "react-router-dom";
import {
  TOKEN_KEY,
  USER,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  VENDORS,
} from "../../constants/defaultKeys";
import Logo from "../../assets/images/logoBlue.png";
import { clear, setItem } from "../../helpers/localStorage";
import { signIn } from "../../services/auth.services";
import "./Login.css";
import {
  isAuthenticated,
  isAdmin,
  getAssignedVendors,
} from "../../helpers/utility";
import CardWizz from "../../blocks/Card/Card";
import { metaData } from "../../services/metaData.services";

const Login = () => {
  const vendors = getAssignedVendors();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) {
      isAdmin()
        ? history.push(`/admin/dashboard`)
        : history.push(`/vendor/${vendors[0]?.vendor_id}/dashboard`);
    }
  }, []);

  const onFinish = (values) => {
    clear();
    setDisabled(true);
    signIn(values)
      .then((response) => {
        setDisabled(true);
        const responseData = response.data;
        if (responseData.token) {
          setItem(TOKEN_KEY, responseData.token);
          setItem(USER, JSON.stringify(responseData));
          history.push("/mfa");
        } else {
          if (
            responseData.payload.is_email_verified &&
            responseData.payload.is_i4_verified &&
            responseData.payload.is_mobile_verified
          ) {
            setItem(ACCESS_TOKEN, responseData.payload.access_token);
            setItem(REFRESH_TOKEN, responseData.payload.refresh_token);
            setItem(USER, JSON.stringify(responseData.payload));

            if (
              responseData.payload.is_admin ||
              responseData.payload.is_client_admin ||
              responseData.payload.is_project_coordinator
            ) {
              history.push("/admin/dashboard");
            } else {
              metaData()
                .then((res) => {
                  const vendors = res.data?.vendors || [];
                  setItem(VENDORS, JSON.stringify({ vendors: vendors }));
                  if (vendors.length) {
                    history.push(`/vendor/${vendors[0].vendor_id}/dashboard`);
                  } else {
                    history.push(`/improperly-configured`);
                  }
                })
                .catch((error) => console.error(error));
            }
          } else {
            setItem(ACCESS_TOKEN, responseData.payload.access_token);
            setItem(REFRESH_TOKEN, responseData.payload.refresh_token);
            setItem(USER, JSON.stringify(responseData.payload));
            history.push("/verification");
          }
        }
      })
      .catch((error) => {
        console.error(error);
        if (error && error.response) {
          message.error(error.response.data.message || error.response.message);
        } else {
          message.error("Error Logging in");
        }
        setDisabled(false);
      });
  };

  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle" className="login">
        <Col
          lg={{ span: 8, offset: 8 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 20, offset: 2 }}
          xs={{ span: 20, offset: 2 }}
          style={{ marginLeft: 0 }}
        >
          <CardWizz bordered={false}>
            <div style={{ textAlign: "center", paddingBottom: "20px" }}>
              <img src={Logo} alt="check logo" height="52px" />
            </div>

            <Form name="login" onFinish={onFinish} scrollToFirstError>
              <Form.Item
                name="username"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email",
                  },
                ]}
              >
                <Input autoFocus={true} size="large" placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  className="login-form-button"
                  disabled={disabled}
                >
                  Sign In
                </Button>
              </Form.Item>
            </Form>
            <div style={{ textAlign: "center" }}>
              <Link to="/forgot-password" className="footer-links">
                Forgot password?
              </Link>
            </div>
          </CardWizz>
          <div
            style={{
              textAlign: "center",
              marginTop: "30px",
              color: "#ffffff",
              fontWeight: "500",
            }}
          >
            You must have a i4Strategies Vendor account to log in. If you need
            assistance, contact Member Services at{" "}
            <a href="mailto:info@i4strategies.com">info@i4strategies.com </a> or
            call <a href="tel:+13216876733">+1 (321) 687-6733</a>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
export default Login;
