import { Col, Row, Typography } from "antd";

const { Paragraph } = Typography;

const Support = () => {
  return (
    <Row type="flex">
      <Col span={20} offset={1}>
        <Paragraph>
          If at any time during this process you have questions or concerns,
          please contact us at the below and we will get back to you as soon as
          possible:
        </Paragraph>

        <Paragraph>- Email: info@i4strategies.com</Paragraph>
        <Paragraph>- Phone: (212) 909-9589</Paragraph>
      </Col>
    </Row>
  );
};

export default Support;
